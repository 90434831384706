onload = onresize = function() {
  var cols = document.querySelectorAll('[data-match-height]'),
      encountered = []
  for (i = 0; i < cols.length; i++) {
    var attr = cols[i].getAttribute('data-match-height')
    if (encountered.indexOf(attr) == -1) {
    encountered.push(attr)
    }
  }
  for (set = 0; set < encountered.length; set++) {
    var col = document.querySelectorAll('[data-match-height="' + encountered[set] + '"]'),
        group = []
    for (i = 0; i < col.length; i++) {
      col[i].style.height = 'auto'
      group.push(col[i].scrollHeight)
    }
    for (i = 0; i < col.length; i++) {
      col[i].style.height = Math.max.apply(Math, group) + 'px'
    }
  }
}
