import Cookies from 'js-cookie'

window.addEventListener('DOMContentLoaded', (event) => {
  if (!Cookies.get('cookies-policy')) {
    $('.cookies').addClass('is-visible')
  }

  $('.js-cookies-close').on('click', function (e) {
    e.preventDefault()
    $('.cookies').removeClass('is-visible')
    Cookies.set('cookies-policy', 'true', { expires: 365 })
  })
})
