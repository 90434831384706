window.addEventListener('DOMContentLoaded', (event) => {
  var mobileNavHeaders = Array.prototype.slice.call(document.querySelectorAll('.mobile-nav-header-has-sbumenu'))

  mobileNavHeaders.forEach(function (link) {
    link.addEventListener('click', function (event) {
      event.preventDefault()
      this.classList.toggle('is-expanded')
    })
  })
})
