import MoveTo from 'moveto'

window.addEventListener('DOMContentLoaded', (e) => {
  var moveTo = new MoveTo({
    ease: 'easeInQuad',
    tolerance: 0
  })
  var triggers = document.getElementsByClassName('js-move-to')
  for (var i = 0; i < triggers.length; i += 1) {
    moveTo.registerTrigger(triggers[i])
  }
})
