window.addEventListener('DOMContentLoaded', (e) => {
  $('a.share-button').click(function (e) {
    e.preventDefault()
    var $link = $(this)
    var href = $link.attr('href')
    var network = $link.attr('data-network')

    var networks = {
      facebook: { width: 600, height: 300 },
      twitter: { width: 600, height: 254 }
    }

    var popup = function (network) {
      var options = 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,'
      window.open(href, '', options + 'height=' + networks[network].height + ', width=' + networks[network].width)
    }

    popup(network)
  })
})
