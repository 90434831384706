import MicroModal from 'micromodal'

window.addEventListener('DOMContentLoaded', (e) => {
  MicroModal.init()
  if (document.getElementsByClassName('modal').length) {
    MicroModal.init({
      onShow: function () {
        document.getElementsByTagName('BODY')[0].classList.add('overflow-hidden')
      },
      onClose: function () {
        document.getElementsByTagName('BODY')[0].classList.remove('overflow-hidden')
      }
    })
  }
})
