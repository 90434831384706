window.addEventListener('DOMContentLoaded', (event) => {
  var openProfileSidebar = document.getElementById('js-open-profile-sidebar')
  var closeProfileSidebar = document.getElementById('js-close-profile-sidebar')
  var bodyEl = document.getElementById('profile-body')

  if (openProfileSidebar) {
    openProfileSidebar.addEventListener('click', function (e) {
      e.preventDefault()
      bodyEl.classList.add('profile-sidebar-is-visible')
    })

    closeProfileSidebar.addEventListener('click', function (e) {
      e.preventDefault()
      bodyEl.classList.remove('profile-sidebar-is-visible')
    })
  }
})
