import Instafeed from 'instafeed.js'

window.addEventListener('DOMContentLoaded', (e) => {
  if (document.getElementById('instafeed') !== null) {
    var userFeed = new Instafeed({
      get: 'user',
      userId: '5549453396',
      accessToken: '5549453396.9ec7b1b.c350cb87f50e4f729e05127df3c16fa5',
      resolution: 'standard_resolution',
      template: '<a href="{{link}}" target="_blank"><img src="{{image}}" /><div class="rollover"><i class="rollover-icon"></i><span class="instaname">@hektarnektar</span><span class="instacaption">{{model.hashtag_caption}}</span></div></a>',
      sortBy: 'most-recent',
      limit: 10,
      links: true,
      filter: function (image) {
        function findHashtags (searchText) {
          var regexp = /(\s|^)\#\w\w+\b/gm
          var result = searchText.match(regexp)
          if (result) {
            result = result.map(function(s) { return s.trim () })
            return result.join(' ')
          } else {
            return false
          }
        }

        if (image.caption && image.caption.text) {
          image.hashtag_caption = findHashtags(image.caption.text)
        } else {
          image.hashtag_caption = ''
        }
        return true
      }
    })
    userFeed.run()
  }
})
