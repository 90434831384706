import Headroom from 'headroom.js'

window.addEventListener('DOMContentLoaded', (event) => {
  var rootLinksDdrop = Array.prototype.slice.call(document.querySelectorAll('.root-link-drop'))
  rootLinksDdrop.forEach(function (link) {
    link.addEventListener('click', function (e) {
      e.preventDefault()
    })
  })

  var pageHeader = document.getElementById('js-page-head')
  if (pageHeader) {
    var headroom = new Headroom(pageHeader,
      {
        offset: 80,
        classes: {
          pinned: 'nav-down',
          unpinned: 'nav-up'
        }
      }
    )
    headroom.init()
  }
})
