import Colcade from 'colcade'

window.addEventListener('DOMContentLoaded', (event) => {
  if (document.getElementById('js-grid') !== null) {
    var colc = new Colcade('.grid', {
      columns: '.grid-col',
      items: '.grid-item'
    })
  }
})
